<template>
  <div class="Vacancy">
    <div class="Wrapper">
      <h1 class="Vacancy-Title Title">{{ vacancy.name }}</h1>

      <div class="Vacancy-Region VacancyRegion">
        {{ vacancy.region }}
      </div>
        
      <ul class="Vacancy-Features VacancyFeatures">
        <li
          class="VacancyFeatures-Item"
          v-for="(item, i) in vacancy.features"
          :key="i"
        >
          {{ item }}
        </li>
      </ul>

      <div class="Vacancy-Description VacancyDescription" v-html="vacancy.description"/>
    </div>

    <FormBox/>
  </div>
</template>

<script>
import FormBox from '../components/FormBox'

export default {
  name: 'Vacancy',
  components: {
    FormBox
  },
  props: {
    id: {
      type: String,
      default: null,
      require: true
    }
  },
  computed: {
    vacancy() {
      return this.$t('vacancy').filter(({ id }) => parseInt(id) === parseInt(this.id))[0]
    }
  }
}
</script>

<style lang="scss">
  .Vacancy {
    max-width: 815px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 60px;

    &-Title {
      margin-bottom: 26px;
      font-size: 35px;
      font-weight: 700;
      line-height: 1.18;
      
      @media (min-width: $screen-l) {
        margin-bottom: 39px;
        font-size: 52px;
      }
    }

    &-Region {
      margin-bottom: 26px;
      
      @media (min-width: $screen-l) {
        margin-bottom: 40px;
      }
    }

    &-Features {
      margin-bottom: 40px;
      
      @media (min-width: $screen-l) {
        margin-bottom: 80px;
      }
    }
  }
</style>
